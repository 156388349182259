<template>
  <div>
    <cp-head-table
      :title="$t('issuerPrerenderedAddresses.title')"
      :button-title="$t('issuerPrerenderedAddresses.button.address.add')"
      title-icon="ios-build"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />
    <div
      v-if="!currencies.length"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('issuerPrerenderedAddresses.loading') }}
      </div>
    </div>
    <cp-table
      v-else
      ref="cpTable"
      get-data-action="issuerPrerenderedAddresses/getIssuerPrerenderedAddresses"
      :url-params="urlParams"
      :fields="fields"
      :data-parser="parseData"
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>

      <template
        slot="updatedAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.updatedAt | dateFilter }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.delete')"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.delete') }}
        </b-btn>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-prerendered-address-modal
      ref="CpIssuerPreAddresses"
      :modal-data="modalData"
      :title-data="titleData"
      :options="parsedCurrencies"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpPrerenderedAddressModal from './issuer-pre-address-modal';

import fields from './fields';

export default {
  name: 'IssuerPrerenderedAddresses',

  metaInfo: {
    title: 'Issuer Prerendered addresses',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpDeleteModal,
    CpPrerenderedAddressModal,
  },

  data() {
    return {
      modalData: {},
      titleData: {},
      deleteItem: null,
      fields,
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },

  computed: {
    ...mapState('issuerCurrencies', ['currencies']),
    parsedCurrencies() {
      return this.currencies.map(({ id, name, identifier }) => ({
        value: id,
        text: `${name} (${identifier})`,
      }));
    },
  },

  created() {
    if (!this.currencies.length) this.getIssuerCurrenciesList(this.urlParams.issuerId);
  },
  methods: {
    ...mapActions('issuerPrerenderedAddresses', [
      'create',
      'update',
      'getById',
      'delete',
    ]),
    ...mapActions('issuerCurrencies', ['getIssuerCurrenciesList']),
    parseData(data) {
      return data.map((item) => {
        const currentCurrency = this.currencies.find(currency => currency.id === item.currencyId);
        return {
          ...item,
          currencyId: currentCurrency && currentCurrency.name,
        };
      });
    },

    hideModal() {
      this.$refs.CpIssuerPreAddresses.$refs.issuerPreAddress.hide();
    },

    showModal() {
      this.$refs.CpIssuerPreAddresses.$refs.issuerPreAddress.show();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        label: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.modalData = {};
      this.showModal();
    },

    toEditModal({ id }) {
      this.titleData = {
        type: 'Edit',
        label: this.$t('common.button.edit'),
        icon: 'md-create',
      };

      this.getById({
        issuerId: this.$route.params.idIssuer,
        id,
      }).then((data) => {
        this.modalData = data;
        this.showModal();
      });
    },

    removeItem() {
      const { id } = this.deleteItem;
      this.delete({
        issuerId: this.$route.params.idIssuer,
        id,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    saveChanges({ action, address }) {
      address.derivativeId = +address.derivativeId || 0;
      this[action]({
        issuerId: this.$route.params.idIssuer,
        address,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },
  },
};
</script>

<style lang="scss">
  .table {
    tr {
      td:nth-child(2) {
        white-space: nowrap;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
