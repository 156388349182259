const tdClass = 'align-middle';

export default [
  {
    key: 'address', i18nKey: 'issuerPrerenderedAddresses.label.table.address', tdClass,
  },
  {
    key: 'addressSignature', i18nKey: 'issuerPrerenderedAddresses.label.table.addressSignature', tdClass,
  },
  {
    key: 'derivativeId', i18nKey: 'issuerPrerenderedAddresses.label.table.derivativeId', tdClass,
  },
  {
    key: 'currencyId', i18nKey: 'issuerPrerenderedAddresses.label.table.currencyId', tdClass,
  },
  {
    key: 'createdAt', i18nKey: 'issuerPrerenderedAddresses.label.table.createdAt', tdClass,
  },
  {
    key: 'updatedAt', i18nKey: 'issuerPrerenderedAddresses.label.table.updatedAt', tdClass,
  },
  {
    key: 'actions', label: ' ', tdClass: `text-nowrap ${tdClass} text-center`,
  },
];
