<template>
  <cp-general-modal
    ref="issuerPreAddress"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.address"
      name="address"
      :label="$t('issuerPrerenderedAddresses.prerenderedAddressModal.input.address')"
    />
    <cp-select
      v-model="model.currencyId"
      name="currencyId"
      :label="$t('issuerPrerenderedAddresses.prerenderedAddressModal.input.currencyId')"
      :options="options"
    />
    <cp-input
      v-model="model.derivativeId"
      name="derivativeId"
      :label="$t('issuerPrerenderedAddresses.prerenderedAddressModal.input.derivativeId')"
    />
    <div
      v-if="titleData.type === 'Edit'"
      class="row mt-2"
    >
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('issuerPrerenderedAddresses.prerenderedAddressModal.label.createdAt') }}
        </label> <br>
        <span>
          {{ model.createdAt | dateFilter }}
        </span>
      </div>
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('issuerPrerenderedAddresses.prerenderedAddressModal.label.updatedAt') }}
        </label>  <br>
        <span>
          {{ model.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'IssuerPrerenderedAddressModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    modalData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      model: {},
    };
  },

  computed: {
    getTitleName() {
      return this.$t('issuerPrerenderedAddresses.prerenderedAddressModal.title', [this.titleData.label]);
    },
  },

  watch: {
    modalData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit() {
      const action = this.model.id ? 'update' : 'create';
      this.$emit('onSubmit', { action, address: this.model });
    },
  },
};
</script>
